* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #fff;
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat;
  letter-spacing: -0.05em;
}
.Sidebar {
  width: 280px;
  background-color: #1e95fb;
  padding: 30px;
  height: 100vh;
  position: fixed;
}
.Sidebar h1 {
  margin: 0;
  color: #fff;
  line-height: 0.8em;
}
.Sidebar p {
  width: 180px;
  color: #fff;
  top: 100px;
  font-size: 0.75em;
}
.Navigation {
  list-style: none;
  padding: 0;
  position: fixed;
  top: 100px;
}
.Navigation__item {
  padding-bottom: 0.1em;
}
.Navigation__link {
  transition: color 200ms;
  color: #fff;
  font-size: 1em;
  text-decoration: none;
}
.Navigation__link:hover {
  text-decoration: underline;
}
.Portfolio {
  width: 100%;
  float: right;
  padding: 30px 30px 30px 310px;
}
.Filter {
  width: calc(99.99% * 1 - (30px - 30px * 1));
  height: calc(99.99% * 1 - (30px - 30px * 1));
  background-color: #1e95fb;
  padding: 0 10px;
}
.Filter:nth-child(1n) {
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
  clear: none;
}
.Filter:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
.Filter:nth-child(undefinedn) {
  margin-right: 0;
  float: right;
}
.Filter:nth-child(undefinedn + 1) {
  clear: left;
}
.Filter:nth-last-child(-n + undefined) {
  margin-bottom: 0;
}
.Tags {
  list-style: none;
  padding: 0;
  font-size: 0.7em;
  color: #777;
}
.Tags__item {
  display: inline-block;
  padding: 0.5em;
  margin: 0.2em;
  transition: all 200ms;
  color: #1e95fb;
  border: 1px solid #1e95fb;
}
.Tags__item--inverted {
  color: #fff;
  border-color: #fff;
}
.Tags__item:hover,
.Tags__item--filtered {
  cursor: pointer;
  background-color: #1e95fb;
  color: #fff;
}
.Tags__item--inverted:hover,
.Tags__item--inverted.Tags__item--filtered {
  background-color: #fff;
  color: #1e95fb;
}
.Project__summary,
.Project__title {
  margin: 15px 0;
  transition: color 200ms;
  font-size: 1.1em;
  color: #606d7c;
}
.Project__link {
  font-size: 0.6em;
  text-decoration: none;
  color: #1e95fb;
}
.Project__summary-text {
  padding: 10px;
  color: #fff;
  background-color: #ffa74b;
  font-size: 0.8em;
}
.Project__image {
  width: 100%;
  transition: all 200ms;
  opacity: 0.75;
  box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
}
.Project:hover .Project__image {
  opacity: 0.9;
  transform: scale(1.02);
  box-shadow: 0px 0px 40px rgba(0,0,0,0.25);
}
@media screen and (min-width: 1024px) {
  .Project {
    width: calc(99.99% * 1/3 - (30px - 30px * 1/3));
    height: calc(99.99% * 1/3 - (30px - 30px * 1/3));
  }
  .Project:nth-child(1n) {
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
    clear: none;
  }
  .Project:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .Project:nth-child(3n) {
    margin-right: 0;
    float: right;
  }
  .Project:nth-child(3n + 1) {
    clear: left;
  }
  .Project:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1024px) {
  .Project {
    width: calc(99.99% * 1/2 - (30px - 30px * 1/2));
    height: calc(99.99% * 1/2 - (30px - 30px * 1/2));
  }
  .Project:nth-child(1n) {
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
    clear: none;
  }
  .Project:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .Project:nth-child(2n) {
    margin-right: 0;
    float: right;
  }
  .Project:nth-child(2n + 1) {
    clear: left;
  }
  .Project:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 480px) {
  .Project {
    width: calc(99.99% * 1 - (30px - 30px * 1));
    height: calc(99.99% * 1 - (30px - 30px * 1));
  }
  .Project:nth-child(1n) {
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
    clear: none;
  }
  .Project:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .Project:nth-child(undefinedn) {
    margin-right: 0;
    float: right;
  }
  .Project:nth-child(undefinedn + 1) {
    clear: left;
  }
  .Project:nth-last-child(-n + undefined) {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .Sidebar {
    display: none;
  }
  .Portfolio {
    padding: 30px;
  }
}

// $primary_color = #379FCA
$primary_color = #1E95FB
$secondary_color = saturate(lighten(complement($primary_color), 30%), 30%)
//orange #FFC967

*
	box-sizing:border-box
	font-family: 'Open Sans', sans-serif

body, html
	padding:0
	margin:0
	height:100%
	background-color:white
	font-weight: 300

h1, h2, h3, h4, h5, h6
	font-family:Montserrat
	letter-spacing: -0.05em

.container
	// display:flex
	
.Sidebar
	width:280px
	background-color: $primary_color
	padding: 30px
	height: 100vh
	position: fixed
	
	& h1
		margin: 0
		color: white
		line-height: 0.8em
		
	& p
		width: 180px
		color: white
		top: 100px
		font-size: 0.75em
		

.Navigation
	list-style: none
	padding:0
	position: fixed
	top: 100px
	
	&__item
		padding-bottom: 0.1em

	&__link
		transition:color 200ms
		color:white
		font-size: 1em
		text-decoration:none
		
	&__link:hover
		text-decoration:underline
			
.Portfolio
	width: 100%
	float:right
	padding:30px 30px 30px 310px	
	
.Filter
	lost-waffle:1
	background-color: $primary_color
	padding: 0 10px


.Tags
	list-style: none
	padding: 0
	font-size: 0.7em
	color: #777

	&__item
		display: inline-block
		padding: 0.5em
		margin: 0.2em
		transition:all 200ms
		color: $primary_color
		border: 1px solid $primary_color
		
	&__item--inverted
		color: white
		border-color: white
		
	&__item:hover, &__item--filtered
		cursor: pointer
		background-color: $primary_color
		color: white
		
	&__item--inverted:hover, &__item--inverted&__item--filtered
		background-color: white
		color: $primary_color

.Project

	&__summary

	&__title
		margin:15px 0
		transition:color 200ms
		font-size:1.1em
		color:#606D7C
		
	&__link
		font-size: 0.6em
		text-decoration: none
		color: $primary_color
		
	&__summary-text
		padding:10px
		color:white
		background-color:$secondary_color
		font-size: 0.8em;

	&__image
		width:100%
		transition:all 200ms
		opacity: 0.75
		box-shadow: 0px 0px 30px rgba(0,0,0,0.2)
		
	&:hover &__image
		opacity:0.9
		transform: scale(1.02)
		box-shadow: 0px 0px 40px rgba(0,0,0,0.25)
			
@media screen and (min-width: 1024px)
	.Project
		lost-waffle:1/3
		
@media screen and (max-width: 1024px)
	.Project
		lost-waffle:1/2

@media screen and (max-width: 480px)
	.Project
		lost-waffle:1

@media screen and (max-width: 768px)
	.Sidebar
		display: none
		
	.Portfolio
		padding:30px
		